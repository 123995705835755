import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';
import baseDropdownMixin from '../BaseComponents/mixins/base-droppown.mixin.js';

export default {
  name: 'autocomplete-helper',
  mixins: [baseDropdownMixin],
  props: {
    presetValue: [String, Object],
    modelValue: [String, Object],
    // value: String,
    id: String,
    minChars: {
      type: Number,
      default() {
        return 1;
      },
    },
    selectFirst: {
      type: Boolean,
      default() {
        return false;
      },
    },
    autoSelectOnBlur: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    const debounceUpdate = debounce(() => {
      this.$emit(
        'search',
        this.query,
        (loading) => {
          this.loading = loading;
          this.debounceUpdateDisplayDM();
          if (!loading && this.query) {
            this.current = -1;
            if (this.selectFirst) {
              this.down();
            }
          }
        },
        this.name,
      );
    }, 300);
    const debounceUpdateDisplayDM = debounce(() => this.updateDisplayDropdownMenu(), 100);
    return {
      query: '',
      loading: false,
      focused: false,
      idElement: null,
      ignorePresetValue: false,
      canceledTimeout: null,
      debounceUpdate,
      debounceUpdateDisplayDM,
    };
  },
  watch: {
    options(val) {
      if (!this.modelValue && val.length) {
        this.setItems(val);
      } else {
        this.setItems();
      }
    },
    modelValue(val) {
      this.setQuery(val);
    },
  },
  computed: {
    isEmpty() {
      return !this.query;
    },

    isDirty() {
      return !!this.query;
    },
    isEmptyValue() {
      return !this.modelValue;
    },
    isEmptyResults() {
      return this.items.length === 0;
    },
  },
  methods: {
    updateDisplayDropdownMenu() {
      this.debounceUpdateDisplayDM.cancel();
      const hasResult = !this.isEmptyResults;
      const hasEmptyResult = this.isEmptyResults;
      const hasQuerySuccess =
        this.focused && !this.loading && this.minChars <= this.query?.length && this.isEmptyValue;
      this.displayDropdownMenu = hasQuerySuccess && (hasResult || hasEmptyResult);
    },
    update(value = '') {
      this.query = value;
      if (this.modelValue && this.getLabel(this.modelValue) !== this.query) {
        this.selectValue(null);
        this.query = this.findFirstDiff(this.getLabel(this.modelValue), this.query)?.trim();
      }
      this.debounceUpdateDisplayDM();
      if (this.query?.length < this.minChars) {
        return;
      }
      this.cancel();
      this.loading = true;
      this.debounceUpdate();
    },
    cancel() {
      // used to 'cancel' previous searches
      this.debounceUpdate.cancel();
      this.$emit('searchCancel');
    },
    focus() {
      if (this.canceledTimeout) clearTimeout(this.canceledTimeout);
      this.focused = true;
      if (this.minChars === 0 && this.minChars === this.query?.length && this.isEmptyValue) {
        this.update();
      }
    },
    blur() {
      this.focused = false;
      if (this.autoSelectOnBlur && this.items.length && this.query) {
        this.onHit(this.items[0]);
      }
      this.canceledTimeout = setTimeout(() => {
        this.reset();
      }, 300);
    },
    reset() {
      if (this.modelValue && this.getLabel(this.modelValue)) {
        this.query = this.getLabel(this.modelValue);
      } else if (!this.ignorePresetValue && this.presetValue) {
        this.query = this.presetValue;
      } else {
        this.query = '';
      }
      this.setItems();
      this.loading = false;
      this.updateDisplayDropdownMenu();
    },
    clear() {
      this.ignorePresetValue = true;
      this.$emit('reset-preset-value', {
        ignorePresetValue: this.ignorePresetValue,
        value: this.presetValue,
      });
      this.selectValue(null);
      setTimeout(() => {
        this.reset();
      });
    },

    onHit(item) {
      this.reset();
      this.query = this.getLabel(item);
      this.selectValue(item);
      this.current = -1;
      this.debounceUpdateDisplayDM();
    },
    selectValue(item) {
      let value = null;
      if (item) {
        value = this.getValue(item);
      }
      this.$emit('select', value);
      this.$emit('update:modelValue', value);
    },
    setQuery(val) {
      if (val && this.getLabel(val)) {
        this.query = this.getLabel(val);
      } else if (!this.ignorePresetValue && this.presetValue) {
        this.query = this.presetValue;
      }
    },
    findFirstDiff(str1, str2) {
      if (!str2 || !str1) {
        return '';
      }

      return str2[[...str1].findIndex((el, index) => el !== str2[index])];
    },
  },
  mounted() {
    this.idElement = this.id || uniqueId('autocomplete_');
    this.setQuery(this.modelValue);
  },
};
