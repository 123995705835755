import { cdekConsoleLog } from '@/utils/console-wrapper';

export function addedGAEvent(event: string) {
  cdekConsoleLog(event);
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event,
    });
}
