<template>
  <div class="help-customer-container-view">
    <div ref="header" class="help-customer-container-view__header">
      <button class="help-customer-container-view__back" type="button" @click="onBack">
        <icon-arrow />
      </button>
      <div class="help-customer-container-view__title">
        {{ view.title }}
      </div>
    </div>
    <div ref="box" class="help-customer-container-view__box">
      <div ref="scroll" class="help-customer-container-view__scroll">
        <div class="help-customer-container-view__content" v-html="view.text" />
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '../icon-svg/IconArrow.vue';

export default {
  name: 'HelpCustomerContainerView',
  components: { IconArrow },
  props: {
    view: { type: Object, required: true },
    opened: Boolean,
  },
  emits: ['back'],
  watch: {
    opened(val) {
      this.toggle(val);
    },
  },
  mounted() {
    this.toggle(this.opened);
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
    setScrollHeader() {
      const headerEl = this.$refs.header;
      const styleHeader = window.getComputedStyle(headerEl);
      const heightHeader = parseFloat(styleHeader.height);
      this.$refs.box.style.paddingTop = `${heightHeader}px`;

      const cookieEl = document.querySelector('.cookie-alert');
      const cookieHeight = cookieEl?.clientHeight || 0;
      // Магическое число примерно равное всем лишним паддингам и маржинам на странице
      // Чтобы виджет влезал на экран
      const maxHeight = window.innerHeight - heightHeader - cookieHeight - 80;

      this.$refs.scroll.style.maxHeight = `${maxHeight}px`;
    },
    resetScrollHeader() {
      this.$refs.scroll.style.height = 'auto';
      this.$refs.header.removeAttribute('style');
      this.$refs.box.removeAttribute('style');
    },
    toggle(val) {
      if (val) {
        this.setScrollHeader();
      } else {
        this.resetScrollHeader();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './variables';

.help-customer-container-view {
  position: relative;
  width: 100%;
  height: 100%;

  &__header {
    position: absolute;
    top: 0;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 15px 25px 10px;
    border-bottom: 1px solid $baseBorderColor;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  &__back {
    margin-top: 4px;
    padding: 0 16px 0 0;
    outline: none;
    font-weight: bold;
    line-height: 1;
  }

  &__box {
    height: 100%;
  }

  &__scroll {
    overflow-y: auto;
    padding: 8px 24px;
  }
}
</style>
