<script>
import { computed, h, nextTick, ref, unref } from 'vue';
import useBaseField from '@/composables/use-base-field';
import baseFieldMixin from './mixins/base-field.mixin';

export default {
  name: 'BaseTextArea',
  mixins: [baseFieldMixin],
  props: {
    rows: {
      type: [String, Number],
      default: 0,
    },
    cols: {
      type: [String, Number],
      default: 0,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['focus', 'blur', 'input', 'update:modelValue'],
  setup(props, { emit, slots }) {
    const field = ref(null);

    const {
      groutClasses,
      baseClassName,
      rootClasses,
      getPlaceholder,
      getError,
      getAdditionalInfo,
      focus,
      focused,
    } = useBaseField(props, { slots }, field);

    const fieldListeners = computed(() => {
      return {
        onFocus() {
          focused.value = true;
          emit('focus');
        },
        onBlur() {
          focused.value = false;
          emit('blur');
        },
        onInput(event) {
          emit('input', event.target.value, event);
          emit('update:modelValue', event.target.value, event);
        },
      };
    });

    const containerGroutClasses = groutClasses;
    const textareaAttrs = computed(() => {
      const attrs = {};
      if (props.rows) {
        attrs.rows = props.rows;
      }
      if (props.cols) {
        attrs.cols = props.cols;
      }
      if (props.maxlength) {
        attrs.maxlength = props.maxlength;
      }
      return attrs;
    });

    const textareaConf = computed(() => {
      return {
        class: {
          [`${baseClassName.value}__field`]: true,
        },
        value: props.modelValue,
        ...unref(textareaAttrs),
        ...unref(fieldListeners),
      };
    });

    const rootConf = computed(() => {
      return {
        class: rootClasses.value,
      };
    });

    const render = () => {
      const inputElement = h('textarea', textareaConf.value);
      nextTick(() => {
        field.value = inputElement.el;
      });

      const placeholderElement = getPlaceholder();
      const containerGroutChildren = [inputElement].concat(placeholderElement);

      const containerGroutConfig = {
        class: unref(containerGroutClasses),
      };

      const containerGrout = h('div', containerGroutConfig, containerGroutChildren);

      const errorElement = getError();

      const additionalInfo = getAdditionalInfo();

      return h('div', rootConf.value, [containerGrout].concat(errorElement).concat(additionalInfo));
    };

    return { render, focus };
  },
  render() {
    return this.render();
  },
};
</script>
