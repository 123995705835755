<template>
  <label class="base-checkbox" :class="classes">
    <input
      type="checkbox"
      :checked="modelValue"
      :name="name"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @change="onChange"
    />
    <div class="base-checkbox__text"><slot /></div>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    checked: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    primary: {
      type: Boolean,
      default() {
        return true;
      },
    },
    round: {
      type: Boolean,
      default() {
        return true;
      },
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    classes() {
      return {
        'base-checkbox--primary': this.primary,
        'base-checkbox--round': this.round,
      };
    },
  },
  methods: {
    onChange(e) {
      this.$emit('update:modelValue', e.target.checked);
      this.$emit('change', e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  position: relative;
  display: inline-block;
  z-index: 1;

  &.base-checkbox--primary {
    .base-checkbox__text {
      &::before {
        transition: all 0.3s;
        background-color: white;
        border: 1px solid #ddd;
      }

      &::after {
        border: 2px solid white;
        border-right: 0 solid transparent;
        border-top: 0 solid transparent;
      }
    }

    input:checked + .base-checkbox__text::before {
      background-color: $primary-base;
      border-color: $primary-base;
    }
  }

  &.base-checkbox--round {
    .base-checkbox__text {
      &::before {
        border-radius: 5px;
      }
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: -9999px;
    outline: none;

    &:checked + .base-checkbox__text::after {
      outline: none;
      opacity: 1;
      transition: all 0.3s;
    }

    &:disabled + .base-checkbox__text {
      &::before,
      &::after {
        cursor: not-allowed;
      }
    }
  }

  &__text {
    padding-left: 31px;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;

    &::before {
      position: absolute;
      content: '';
      z-index: 5;
      border: 1px solid $DarkGrayLink;
      width: 21px;
      height: 21px;
      top: 0;
      left: 0;
      cursor: pointer;
      background-color: $LightGray;
    }

    &::after {
      position: absolute;
      content: '';
      z-index: 10;
      border: 3px solid #000;
      border-right: 0 solid #fff;
      border-top: 0 solid #fff;
      top: 6px;
      left: 6px;
      width: 10px;
      height: 7px;
      transform: rotate(-45deg);
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
