<template>
  <div class="mobile-drawer-header">
    <slot>
      <img class="mobile-drawer-header__logo" :src="siteLogo.src" alt="logo" />
    </slot>
    <button class="mobile-drawer-header__close" @click="closeMenu">
      <mobile-drawer-close />
    </button>
  </div>
</template>
<script setup>
import { useMenuStore } from '@/store/pinia/menu/menu';
import useSettings from '@/composables/use-settings';
import MobileDrawerClose from './icons/mobile-drawer-close-icon.svg';

const menuStore = useMenuStore();

const { getSettingsGroup } = useSettings();

const siteLogo = getSettingsGroup('logo');
const closeMenu = () => {
  menuStore.closeMobileMenu();
};
</script>

<style scoped lang="scss">
.mobile-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: var(--header-height);

  .mobile-drawer-header__cap {
    width: 31px;
    height: 31px;
    position: absolute;
    top: 2px;
    left: 4px;
    transform: rotate(-30deg);
  }

  &__logo {
    max-width: 87px;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}
</style>
