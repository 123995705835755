/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { globalVueErrorsHandler } from '#root/src/utils/handleErrors/handleErrors';

export const cdekConsoleLog = (...args: any) => console.log(...args);
export const cdekConsoleError = (...args: any) => {
  console.error(...args);

  for (const arg of args) {
    if (arg?.dontSendSentry) {
      return;
    }
  }

  globalVueErrorsHandler(args[0], args[1], args[2]);
};
export const cdekConsoleWarn = (...args: any) => console.warn(...args);
export const cdekConsoleInfo = (...args: any) => console.info(...args);
