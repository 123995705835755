export default {
  name: 'base-dropdown-mixin',
  data() {
    return {
      current: -1,
      items: [],
      displayDropdownMenu: false,
    };
  },
  props: {
    appendToBody: {
      type: Boolean,
      default() {
        return false;
      },
    },
    calculatePosition: {
      type: Function,
      /**
       * @param dropdownList {HTMLUListElement}
       * @param component {Vue} current instance of vue select
       * @param width {string} calculated width in pixels of the dropdown menu
       * @param top {string} absolute position top value in pixels relative to the document
       * @param left {string} absolute position left value in pixels relative to the document
       * @return {function|void}
       */
      default(dropdownList, component, { width, top, left }) {
        dropdownList.style.top = top;
        dropdownList.style.left = left;
        dropdownList.style.width = width;
      },
    },
    label: {
      type: String,
      default() {
        return 'name';
      },
    },
    getLabel: {
      type: Function,
      default(option) {
        return option[this.label];
      },
    },
    getKeyList: {
      type: Function,
      default(option) {
        return option.$uniqKey;
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    getKey: {
      type: Function,
      default(option) {
        return option.id || 'simple';
      },
    },
  },
  methods: {
    setActive(index) {
      this.current = index;
    },

    activeClass(index) {
      return {
        active: this.current === index,
      };
    },
    tabindex(index) {
      return this.current === index ? 1 : -1;
    },

    hit(e) {
      if (this.current !== -1) {
        e.preventDefault();
        this.onHit(this.items[this.current]);
      }
    },

    up() {
      if (this.current > 0) {
        this.current -= 1;
      } else if (this.current === -1) {
        this.current = this.items.length - 1;
      } else {
        this.current = -1;
      }
    },

    down() {
      if (this.current < this.items.length - 1) {
        this.current += 1;
      } else {
        this.current = -1;
      }
    },
    setItems(items = []) {
      if (!items) {
        this.items = [];
        return;
      }

      this.items = items.map((option, index) => {
        let props = {};
        let $simple = false;
        if (['string', 'number'].indexOf(typeof option) !== -1) {
          props = {
            name: option,
          };
          $simple = true;
        } else {
          props = {
            ...option,
          };
        }
        return {
          ...props,
          $simple,
          $uniqKey: `${this.getKey(option)}-${index}`,
        };
      });
    },
    getValue(item) {
      if (item.$simple) {
        return this.getLabel(item);
      }
      const val = {
        ...item,
      };
      delete val.$simple;
      delete val.$uniqKey;
      return val;
    },
  },
};
