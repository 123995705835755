import useSiteInfo from '@/composables/use-site-info';
import useLogin from '@/components/Login/use-login';
import useModal from '@/composables/use-modal';
import { defineAsyncComponent } from 'vue';

export const useShowMoveOutModal = () => {
  const MoveOutRequestModal = defineAsyncComponent(
    () => import('@/components/Modal/MoveOutRequestModal/MoveOutRequestModal.vue'),
  );

  const { isLoggedInCabinet } = useSiteInfo();
  const modal = useModal();

  const { onLogin } = useLogin();

  const showMoveOutModal = () => {
    modal.show({ component: MoveOutRequestModal });
  };

  const showModal = () => {
    if (isLoggedInCabinet.value) {
      showMoveOutModal();
    } else {
      onLogin({ isRedirect: true });
    }
  };

  return { showMoveOutModal, showModal };
};
