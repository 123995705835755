<template>
  <vue-final-modal
    content-class="cabinet-modal advert-agreement-modal"
    :name="name"
    :click-to-close="false"
    :classes="['cabinet-modal-container']"
  >
    <modal-close-btn :name="name" @close="answer(false)" />
    <div class="wrap">
      <div class="title">
        {{ t('advertAgreement.title') }}
      </div>
      <div class="description">
        {{ t('advertAgreement.description') }}
      </div>
      <div class="actions">
        <cdek-button class="btn" @click="answer(true)">{{ t('subscribe') }}</cdek-button>
        <cdek-button class="btn" theme="outline" @click="answer(false)">{{
          t('advertAgreement.cancel')
        }}</cdek-button>
      </div>
      <div class="note">
        <i18n-t keypath="advertAgreement.note.text">
          <template #link>
            <a href="https://www.cdek.ru/ru/soglasienareklamu/" target="_blank" class="note__link">
              {{ t('advertAgreement.note.link') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
import { CdekButton } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
import useModal from '@/composables/use-modal';
import useAdvertAgreement from '@/components/Login/use-advert-agreement';
import { reachGoal } from '@/plugins/ym-plugin';
import { ADVERT_AGREEMENT } from '@/constants/metrics';
import ModalCloseBtn from './CloseBtn.vue';

const name = 'advert-agreement-modal';

const { t } = useI18n();
const modal = useModal();
const { save } = useAdvertAgreement();

const answer = (agreed: boolean) => {
  save(agreed);
  modal.hide(name);
};

reachGoal(ADVERT_AGREEMENT.LK_AD);
</script>

<style lang="scss">
.cabinet-modal.advert-agreement-modal {
  @include media-min-md {
    width: 100%;
    max-width: 588px;
    padding: 32px;
    background-image: url('./assets/balloon.png');
    background-size: 310px 310px;
    background-repeat: no-repeat;
    background-position: bottom -68px right -110px;
  }
}
</style>

<style scoped lang="scss">
.wrap {
  @include media-min-md {
    max-width: 408px;
  }
}

.title {
  @include headline-5;

  margin-bottom: 8px;

  @include media-min-md {
    @include headline-4;
  }
}

.description {
  @include body-1;
}

.btn {
  @include media-min-md {
    max-width: 160px;
  }
}

.actions {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-min-md {
    flex-direction: row;
    gap: 12px;
  }
}

.note {
  @include caption-1;

  line-height: 18px;
  color: $Bottom_60;
  margin-top: 24px;
  text-align: center;

  @include media-min-md {
    margin-top: 16px;
    text-align: left;
  }

  .note__link {
    color: $Bottom_60;
  }
}
</style>
