<template>
  <div class="base-field-phone">
    <div class="base-field-phone__container">
      <base-field-select
        class="base-field-phone__code"
        :class="{
          'base-field-phone__code--old': old,
          'base-field-phone__code--order': order,
        }"
        :model-value="code"
        :options="codes"
        label="country_code"
        :old="old"
        :has-error="hasError"
        :light="light"
        :order="order"
        :append-to-body="true"
        :disabled="disabled"
        @select="onSelectCode"
      >
        <template #dropdown-item="{ item }">
          <div class="base-dropdown-item-phone">
            <span class="base-dropdown-item-phone__flag">
              <img :src="item.flag" />
            </span>
            {{ item.country_code }}
          </div>
        </template>
      </base-field-select>
      <base-input
        :id="id"
        v-model="number"
        class="base-field-phone__number"
        :placeholder="placeholder"
        :required="required"
        :has-error="hasError"
        :message-error="messageError"
        :name="name"
        :disabled="disabled"
        :additional-info="additionalInfo"
        :old="old"
        :light="light"
        :order="order"
        type="tel"
        inputmode="tel"
        :mask="mask"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @clear="onClear"
      />
    </div>
    <div v-if="hasError" v-show="hasErrorSlot" class="base-control__error-message">
      <slot name="message" />
    </div>
    <div v-show="hasAdditionalInfoSlot" class="base-control__additional-info">
      <slot name="additionalInfo" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import useSettings from '@/composables/use-settings';
import basePropsMixin from './mixins/base-props.mixin';
import BaseFieldSelect from './BaseFieldSelect.vue';
import BaseInput from './BaseInput.vue';

export default {
  name: 'BaseFieldPhone',
  components: { BaseInput, BaseFieldSelect },
  mixins: [basePropsMixin],

  props: {
    old: {
      type: Boolean,
      default: false,
    },
    localPhoneParams: {
      type: Array,
      default() {
        return [];
      },
    },
    mask: {
      type: [String, Object],
      default: null,
    },
  },
  emits: ['input', 'update:modelValue', 'focus', 'change', 'blur'],
  setup() {
    const { getSettingsGroup } = useSettings();

    const phoneParams = getSettingsGroup('phoneParams');

    return {
      phoneParams,
    };
  },
  data() {
    return {
      focused: false,
      code: null,
      number: null,
    };
  },
  computed: {
    ...mapGetters({
      websiteId: 'websiteId',
    }),
    fill() {
      return this.hasError ? this.$uiBaseColor.redBase : this.$uiBaseColor.gray1;
    },
    hasAdditionalInfoSlot() {
      return !!this.$slots.additionalInfo;
    },
    hasErrorSlot() {
      return !!this.$slots.message;
    },
    codes() {
      return this.localPhoneParams.length ? this.localPhoneParams : this.phoneParams;
    },
  },
  watch: {
    modelValue(val) {
      if (!val) {
        this.number = null;
      }
      if (!this.focused) {
        this.setDefault();
      }
    },
    codes: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    onInput(value) {
      const phoneNumbers = typeof value === 'string' ? value.replace(/[^0-9]/g, '') : '';

      if (this.mask) {
        this.number = value;
      } else {
        this.number = phoneNumbers;
      }

      this.$emit('update:modelValue', this.getPhone(this.code, phoneNumbers));
      this.$emit('input', this.getPhone(this.code, phoneNumbers));
    },
    onClear() {
      this.number = null;
      this.$emit('update:modelValue', this.getPhone(this.code, this.number));
      this.$emit('input', this.getPhone(this.code, this.number));
      this.onChange();
    },
    onFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    onChange() {
      this.$emit('change');
    },
    onBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    onSelectCode(code) {
      this.code = code;
      this.$emit('update:modelValue', this.getPhone(this.code, this.number));
      this.$emit('input', this.getPhone(this.code, this.number));
    },
    getPhone(code, number = '') {
      return number ? `${code ? code.country_code : ''}${number}` : '';
    },
    async setDefault() {
      if (this.modelValue && this.codes.length) {
        const phoneParse = this.$libphonenumber(this.modelValue);

        if (phoneParse) {
          if (this.mask) {
            // Форма крашится если передать в инпут с маской неформатироанные данные
            const im = await import('inputmask');
            this.number = im.default.format(phoneParse.nationalNumber, this.mask);
          } else {
            this.number = phoneParse.nationalNumber;
          }

          if (!this.code) {
            let code;

            if (phoneParse.country) {
              code = this.codes.find(
                (param) => param.country.toLowerCase() === phoneParse.country.toLowerCase(),
              );
            } else if (phoneParse.countryCallingCode) {
              code = this.codes.find(
                (param) => param.country_code === `+${phoneParse.countryCallingCode}`,
              );
            } else {
              code = this.codes[0];
            }

            if (code) {
              this.code = code;
            } else {
              this.code = this.codes[0];
            }
          }
        }
      }

      if (!this.code && this.codes.length && !this.code) {
        const code = this.codes.find((param) => param.country.toLowerCase() === this.websiteId);

        if (code) {
          this.code = code;
        } else {
          this.code = this.codes[0];
        }
      }
    },
  },
};
</script>
