<script lang="ts" setup>
import useFlomni from '@/composables/use-flomni';
import starIcon from './icons/star.svg';

const { isReviewVisibleOnly, isReviewVisible } = useFlomni();
</script>

<template>
  <button v-if="isReviewVisible" class="footer-review-button" @click="isReviewVisibleOnly">
    <starIcon />
    <span v-t="'leaveFeedback'" />
  </button>
</template>

<style lang="scss" scoped>
.footer-review-button {
  display: flex;
  gap: 6px;
  color: #f1b13b;

  @include body-2;
}
</style>
