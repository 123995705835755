<template>
  <div v-if="qrCodeSvg.path || qrCodeSvg.url" class="help-customer-qr">
    <svg v-if="qrCodeSvg.path" :width="size" :height="size">
      <g :transform="transform">
        <path :d="qrCodeSvg.path" />
      </g>
    </svg>
    <img v-else :src="qrCodeSvg.url" alt="qr" />
    <p class="help-customer-qr__text">
      {{ $t('ratingProduct.qrText') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface IHelpCustomerQr {
  qrCodeSvg: {
    path?: string;
    size?: number;
    url?: string;
  };
}

const size = 120;

const props = defineProps<IHelpCustomerQr>();

const transform = computed(() => {
  const ratio = props.qrCodeSvg?.size ? size / props.qrCodeSvg.size : 1;

  return `scale(${ratio})`;
});
</script>

<style scoped lang="scss">
.help-customer-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &__text {
    color: $Bottom-66;
    text-align: center;

    @include caption-1;
  }
}
</style>
