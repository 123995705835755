// Везде где я графкуэль заменяю на автокомплит будет полная копипаста, поэтому вынес сюда
import type { ComponentOptionsMixin } from 'vue';

const autocompleteCityMixin: ComponentOptionsMixin = {
  name: 'AutocompleteCityMixin',
  methods: {
    async autocompleteCity(
      text: string,
      locale: string,
      loading: (isLoading: boolean) => void,
      countryCode: string,
    ) {
      loading && loading(true);
      try {
        return this.$restApi.cityAutocomplete(text, locale, countryCode);
      } catch (e) {
        return [];
      } finally {
        loading && loading(false);
      }
    },
  },
};

export default autocompleteCityMixin;
