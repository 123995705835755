export default {
  props: {
    autoscroll: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    current() {
      if (this.autoscroll) {
        this.maybeAdjustScroll();
      }
    },
  },

  methods: {
    /**
     * Adjust the scroll position of the dropdown list
     * if the current pointer is outside of the
     * overflow bounds.
     * @returns {*}
     */
    maybeAdjustScroll() {
      const optionEl =
        (this.$refs.baseDropdownMenu && this.$refs.baseDropdownMenu.children[this.current]) ||
        false;

      if (optionEl) {
        const bounds = this.getDropdownViewport();
        const { top, bottom, height } = optionEl.getBoundingClientRect();

        if (top < bounds.top) {
          return (this.$refs.baseDropdownMenu.scrollTop = optionEl.offsetTop);
        }
        if (bottom > bounds.bottom) {
          return (this.$refs.baseDropdownMenu.scrollTop =
            optionEl.offsetTop - (bounds.height - height));
        }
      }
    },

    /**
     * The currently viewable portion of the dropdownMenu.
     * @returns {{top: (string|*|number), bottom: *}}
     */
    getDropdownViewport() {
      return this.$refs.baseDropdownMenu
        ? this.$refs.baseDropdownMenu.getBoundingClientRect()
        : {
            height: 0,
            top: 0,
            bottom: 0,
          };
    },
  },
};
