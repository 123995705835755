import useTildaUser from '@/composables/use-tilda-user';
import { useStore } from 'vuex';
import { useAuth } from '@/composables/use-auth/use-auth';

export default function useLogout() {
  const store = useStore();
  const tildaUser = useTildaUser();

  const { initLogout, removeAuthCookie } = useAuth();

  const logOut = async () => {
    removeAuthCookie();

    tildaUser.removeLS();
    await store.dispatch('cabinet/logoutFromCabinet');
    window.history.pushState('', document.title, window.location.pathname);
    window.location.pathname = `/${store.getters.currentLocaleISO}/`;

    await initLogout(`${window.location.origin}/${store.getters.currentLocaleISO}/`);
  };

  return {
    logOut,
  };
}
