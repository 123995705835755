import type { FlomniState } from '@/types/store/flomni';
import type { GetterTree, Module, MutationTree } from 'vuex';
import type MainState from '@/types/store';

const state = (): FlomniState => ({
  displayChat: false,
  state: 'close',
  inline: false,
  isYaMapsBalloonOpened: false,
  isHelpCustomerLoaded: false,
  isExternalOpened: false,
  isFlomniScriptLoaded: false,
  gotFlomniSettings: false,
  messengerButtons: [],
  isFeedbackOnly: false,
  isReviewVisible: true,
});

const mutations: MutationTree<FlomniState> = {
  onInit(store, payload) {
    store.displayChat = payload.displayChat;
    store.inline = payload.inline;
  },
  onUpdateState(store, payload) {
    store.state = payload.state;
  },
  updateYaMapsBalloonOpened(store, payload) {
    store.isYaMapsBalloonOpened = payload;
  },
  setIsHelpCustomerLoaded(state, payload = true) {
    state.isHelpCustomerLoaded = payload;
  },
  setIsExternalOpened(state, payload = true) {
    if (typeof payload === 'object') {
      const { isExternalOpened, isFeedbackOnly = false } = payload;

      state.isExternalOpened = isExternalOpened;
      state.isFeedbackOnly = isFeedbackOnly;

      return;
    }

    state.isExternalOpened = payload;
    state.isFeedbackOnly = false;
  },
  toggleIsExternalOpened(state) {
    state.isExternalOpened = !state.isExternalOpened;
  },
  setIsFlomniScriptLoaded(state, payload = true) {
    state.isFlomniScriptLoaded = payload;
  },
  setGotFlomniSettings(state, payload = true) {
    state.gotFlomniSettings = payload;
  },
  setMessengerButtons(state, payload) {
    state.messengerButtons = payload;
  },
  setisReviewVisible(state, payload) {
    state.isReviewVisible = payload;
  },
};

const actions = {};

const getters: GetterTree<FlomniState, MainState> = {
  displayChat({ displayChat }) {
    return displayChat;
  },
  state({ state }) {
    return state;
  },
  inline({ inline }) {
    return inline;
  },
  isYaMapsBalloonOpened({ isYaMapsBalloonOpened }) {
    return isYaMapsBalloonOpened;
  },
};

export const flomniModule: Module<FlomniState, MainState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
