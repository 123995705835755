import type { DirectiveBinding, VNode } from 'vue';

let handleClickOutside: (e: Event) => void;

export const clickOutsideDirective = {
  $element: null,
  beforeMount(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    handleClickOutside = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;

      // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
      if (exclude) {
        exclude.forEach((refName: string) => {
          if (!clickedOnExcludedEl) {
            if (!binding.instance) {
              return;
            }

            const excludedEl = binding.instance.$refs[refName] as any;

            if (excludedEl) {
              // Судя по всему ни разу не было компонента переданного в ingores этой директивы
              el = excludedEl.$el || excludedEl;
              clickedOnExcludedEl = el.contains(e.target as HTMLButtonElement);
            }
          }
        });
      }

      if (vnode.el && !vnode.el.contains(e.target) && !clickedOnExcludedEl) {
        handler(e);
      }
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  },

  unmounted() {
    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('touchstart', handleClickOutside);
  },
};
