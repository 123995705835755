<template>
  <vue-final-modal class="base-cdek-modal" :name="name">
    <div class="base-cdek-modal__container" :class="containerClass">
      <mobile-close-line class="base-cdek-modal__mobile-controls" @swipe-down="close" />

      <button type="button" class="base-cdek-modal__icon-close" @click="close">
        <slot name="close">
          <icon-close :stroke="'#ADAFB4'" />
        </slot>
      </button>

      <div class="base-cdek-modal__content" :class="contentClass">
        <slot />
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
import MobileCloseLine from '@/components/MobileCloseLine/MobileCloseLine.vue';
import IconClose from '@/components/icon-svg/IconCloseV2.vue';
import useModal from '@/composables/use-modal';
import { modalName } from '@/components/Modal/SelectLoginTypeModal/consts';

interface IBaseCdekModalProps {
  name: string;
  containerClass?: string;
  contentClass?: string;
}

const props = defineProps<IBaseCdekModalProps>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const modal = useModal();

const closeModal = () => {
  modal.hide(props.name || modalName);
};

const close = () => {
  closeModal();
  emit('close');
};
</script>

<style scoped lang="scss">
.base-cdek-modal {
  &__container {
    position: fixed;
    bottom: 0;
    background: $Peak;
    left: 0;
    right: 0;
    border-radius: 10px 10px 0 0;

    @include media-min-sm {
      border-radius: 16px;
      box-shadow: 0 20px 40px -5px rgba(0, 0, 0, 0.2);
      top: 50%;
      left: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
      width: 384px;
    }
  }

  &__mobile-controls {
    height: 28px;

    @include media-min-sm {
      display: none;
    }
  }

  &__content {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    @include media-min-sm {
      padding: 32px;
    }
  }

  &__icon-close {
    display: none;
    position: absolute;
    top: 15px;
    right: 16px;

    @include media-min-sm {
      display: block;
    }
  }
}
</style>
