interface RedirectMapItem {
  from: string;
  to: string;
  withParams?: boolean;
  isCalculate?: boolean;
  isModal?: boolean;
}
export const redirectMap: RedirectMapItem[] = [
  {
    from: 'relocation',
    to: 'relocation/?showModal=true',
  },
  {
    from: 'gifts',
    to: 'cabinet/gifts/',
  },
  {
    from: 'box',
    to: 'cabinet/calculate/',
    isCalculate: true,
  },
  {
    from: 'profi',
    to: 'cabinet/calculate/',
    isCalculate: true,
  },
  {
    from: 'tracking',
    to: 'cabinet/tracking/',
    withParams: true,
  },
  {
    from: 'payment',
    to: 'payment/',
    isModal: true,
  },
];
