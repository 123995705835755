<template>
  <help-customer-container-rating v-if="isShortFaq" :opened="opened" />
  <div class="help-customer-container-list">
    <div ref="box" class="help-customer-container-list__box">
      <div ref="scroll" class="help-customer-container-list__scroll">
        <ul ref="flomniList" class="help-customer-container-list__list">
          <li
            v-for="(item, idx) in list"
            :key="idx"
            class="help-customer-container-list__list-item"
            @click="onSelect(item)"
          >
            <div>{{ item.title }}</div>
            <icon-arrow color="#000" direction="right" class="help-customer-container__icon" />
          </li>
          <a class="help-customer-container-list__list-item link" href="/help" target="_blank">
            Перейти в раздел "Помощь"
            <icon-arrow color="#000" direction="right" class="help-customer-container__icon" />
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconArrow from '@/components/icon-svg/IconArrow.vue';
import { defineAsyncComponent, onMounted, ref, watchEffect } from 'vue';
import type { WidgetQuestionItem } from '@/components/HelpCustomer/types';

const HelpCustomerContainerRating = defineAsyncComponent(
  () => import('./HelpCustomerContainerRating.vue'),
);

const scroll = ref<HTMLDivElement>();

const props = defineProps<{
  list: WidgetQuestionItem[];
  opened: boolean;
  isShortFaq: boolean;
}>();

const emit = defineEmits<{
  (e: 'select', data: WidgetQuestionItem): void;
}>();

const onSelect = (item: WidgetQuestionItem) => {
  emit('select', item);
};

const resetScrollHeight = () => {
  if (!scroll.value) {
    return;
  }

  scroll.value.style.height = 'auto';
};

const toggle = (val: boolean) => {
  if (val) {
    return;
  }

  resetScrollHeight();
};

watchEffect(() => toggle(props.opened));

onMounted(() => {
  toggle(props.opened);
});
</script>

<style scoped lang="scss">
@import './variables';

.help-customer-container-list {
  height: 100%;
  position: relative;

  &__box {
    overflow-y: auto;
    height: 100%;
  }

  &__scroll {
    overflow-y: auto;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    text-decoration: none;
    padding: 14px 30px;
    color: $black-dark;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &.link {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      background-color: $LightGray;
    }
  }

  &__input {
    background-color: #f8f8f8;
    padding: 20px 36px 8px 20px;
    outline: none;
    width: 100%;
  }

  &__clear {
    position: absolute;
    top: 16px;
    right: 8px;
    outline: none;
  }

  &__close {
    width: 25px;
    height: 20px;
  }

  &__label {
    color: #959ba4;
    font-size: 11px;
    position: absolute;
    top: 8px;
    left: 20px;
  }
}
</style>
