<template>
  <div class="base-render-page" :class="pageClass">
    <keep-alive>
      <render-component :page="page" class="base-render-page" :promo="promo" />
    </keep-alive>
  </div>
</template>

<script lang="ts" setup>
import RenderComponent from '@/components/RenderComponent';
import type IPage from '@/types/dto/page';
import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { usePageContext } from '@/composables/use-page-context';
import usePage from '@/composables/use-page';
import useFlomni from '@/composables/use-flomni';
import { createScriptElement, getHeadElement } from '@/utils/dom.utils';
import { handleTildaYm, removeTildaYm } from '@/utils/handle-tilda-ym';
import { AB_SETTINGS, getGroupNameFromCookie } from '@/utils/request-cookie-abc';
import useSiteInfo from '@/composables/use-site-info';
import { sendParams } from '@/plugins/ym-plugin';
import useSettings from '@/composables/use-settings';
import type { PagePromo } from '@/types/dto/page-promo';
import { UTM_BUSINESS } from '@/constants/dogovor';
import { cdekHomePage } from '@/constants/global-variables';

const props = withDefaults(defineProps<{ page: IPage; isTilda?: boolean; promo?: PagePromo[] }>(), {
  isTilda: false,
});

const pageContext = usePageContext();

const { isBusinessPage } = usePage();

const { currentLocale, isRuSite, isAzSite, isKzSite } = useSiteInfo();

const {
  addListenersToLinks,
  removeListenersFromLinks,
  showFlomniComponent,
  isFlomniScriptLoaded,
  gotFlomniSettings,
  messengerButtons,
} = useFlomni();

const { getSettingsGroup } = useSettings();

const { displayHelpCustomer } = getSettingsGroup('all');

provide('pageContent', props.page.content);

const loadedScripts = ref(0);

// При навигации по сайту, head захламляется скриптами которые
// запихиваем на страницах тильды, перед тем как покидаем страницу
// желательно подчищать за собой

const pageClass = computed(() => {
  const customClass =
    `page-${pageContext?.urlPathname?.replace(/^\/[\w\d]+\//, '')?.replace('/', '')}` || '';

  return {
    'base-render-page--tilda': props.isTilda,
    [customClass]: true,
  };
});

const scriptIds = ref<string[]>([]);

const appendScriptToHead = (src: string, idx: number) => {
  const head = getHeadElement();

  const id = `cdek_backend_tilda_script_${idx}`;

  // С бекенда нам приходит набор скриптов this.scriptsJS и верстка страницы тильды.
  // Верстка содержит в себе блоки <script /> в которых происходит вызов функций определяемых в скриптах из this.scriptsJS.
  // В файле nuxt.config.js я замокал функцию t_onReady чтобы при первичной отрисовке страница не ломалась.
  const onload = () => {
    loadedScripts.value += 1;

    // После того как все скрипты загружены можем по новой попытаться сделать тильду интерактивной
    // выполнив то что приходило к нам в верстке
    if (loadedScripts.value === props.page.js.length) {
      const tildaContainer = document.getElementById('allrecords');

      if (!tildaContainer) {
        return;
      }

      const scripts = [...tildaContainer.getElementsByTagName('script')];

      scripts.forEach((el, innerIdx) => {
        const newScript = document.createElement('script');
        const innerId = `cdek_tilda_script_${innerIdx}`;
        scriptIds.value.push(innerId);

        newScript.type = 'text/javascript';
        newScript.innerHTML = el.innerHTML;
        newScript.id = innerId;

        head.appendChild(newScript);
      });

      handleTildaYm();
    }
  };

  scriptIds.value.push(id);

  head.appendChild(createScriptElement({ id, src, defer: true, onload }));
};

const handleTildaPage = () => {
  if (props.page.isTilda) {
    const pageLayout = document.getElementById('work-area');
    if (pageLayout) pageLayout.style.zIndex = '1';
  }

  // Изначально эти скрипты запихивались просто в head. Была проблема с тем что непонятно когда что выполняется
  // Судя по всему скрипты отрабатывали до получения верстки, и страница не становилась интерактивной
  // Поэтому вынес добавление скриптов в хеад только после маунта. Чтобы ожидать одинакового поведения для сср и спа
  props.page?.js?.forEach((src, idx) => {
    appendScriptToHead(src, idx);
  });
};

const removeTildaPage = () => {
  removeTildaYm();

  scriptIds.value.forEach((id) => {
    const scriptToRemove = document.getElementById(id);

    if (scriptToRemove) {
      scriptToRemove.remove();
    }
  });
  loadedScripts.value = 0;
  scriptIds.value = [];
  const pageLayout = document.getElementById('work-area');
  if (pageLayout) pageLayout.style.zIndex = '';
};

const sendVisitParam = () => {
  const { urlPathname, cookies } = pageContext;

  for (const cookieName in AB_SETTINGS) {
    const urls = AB_SETTINGS[cookieName];

    for (const url in urls) {
      if (
        urlPathname === `/${currentLocale.value}${url}` ||
        (url === cdekHomePage && urlPathname === `/${currentLocale.value}/`)
      ) {
        const { groups } = urls[url];

        const cookieValue = parseFloat(cookies[cookieName]);

        const groupName = getGroupNameFromCookie(cookieValue, groups);

        if (groupName) {
          sendParams({
            [cookieName]: groupName,
          });
        }
      }
    }
  }
};

watch(
  () => props.page,
  () => {
    if (isRuSite.value) {
      sendVisitParam();
    }
    removeTildaPage();
    handleTildaPage();
  },
);

watch([isFlomniScriptLoaded, gotFlomniSettings, messengerButtons], () => {
  if (
    displayHelpCustomer &&
    isFlomniScriptLoaded.value &&
    gotFlomniSettings.value &&
    pageContext.urlParsed.hash.includes('isHelpOpen')
  ) {
    if (isRuSite.value || isAzSite.value) {
      if (messengerButtons.value.length) {
        showFlomniComponent();
      }

      return;
    }

    showFlomniComponent();
  }
});

onMounted(() => {
  sendVisitParam();
  handleTildaPage();
  addListenersToLinks();
  if (isBusinessPage.value && isKzSite.value && pageContext.urlParsed.searchOriginal) {
    sessionStorage.setItem(UTM_BUSINESS, pageContext.urlParsed.searchOriginal);
  }
});

onBeforeUnmount(() => {
  removeListenersFromLinks();

  removeTildaPage();
});
</script>

<script lang="ts">
export { default as Layout } from '#root/src/layouts/DefaultLayout.vue';
</script>
