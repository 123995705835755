import { createScriptElement, getHeadElement } from '@/utils/dom.utils';

let pixelId: string | null = null;

export const initVKAdsPlugin = (id = '') => {
  if (!id) {
    return;
  }

  pixelId = id;
  getHeadElement().appendChild(
    createScriptElement({
      text: `
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: ${id}, type: "pageView", start: (new Date()).getTime()});
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
      ts.src = "https://top-fwz1.mail.ru/js/code.js";
      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "tmr-code");
     `,
    }),
  );
};

export const reachVKGoal = (goal: string) => {
  if (!pixelId) {
    return;
  }

  window._tmr?.push({ type: 'reachGoal', id: pixelId, goal });
};
