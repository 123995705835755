<template>
  <vue-final-modal
    :classes="['request-call-modal']"
    content-class="base-modal request-call-modal__content"
    :name="modalName"
  >
    <div v-if="!sended">
      <div class="base-modal__container">
        <div class="base-modal__header">
          <h2 class="base-modal__title">
            {{ $t('leaveRequest') }}
          </h2>
          <div class="base-modal__action">
            <button type="button" @click="onClose">
              <icon-close :width="24" :height="24" />
            </button>
          </div>
        </div>

        <form class="request-form" @submit.prevent="onSubmit">
          <div class="base-modal__content">
            <div class="request-form__group">
              <div class="request-form__row">
                <label class="request-form__label">
                  {{ $t('yourName') }}
                </label>
                <base-input
                  v-model="form.name"
                  :placeholder="$t('enterName')"
                  :has-error="v$.form.name.$error"
                >
                  <template v-if="v$.form.name.$error" #message>
                    {{ $t('requiredField') }}
                  </template>
                </base-input>
              </div>
              <div class="request-form__row">
                <label class="request-form__label">
                  {{ $t('phone') }}
                </label>
                <base-field-phone
                  v-model="form.phone"
                  :placeholder="$t('phone')"
                  :has-error="v$.form.phone.$error"
                  :local-phone-params="codes"
                  @blur="v$.form.phone.$touch()"
                >
                  <template v-if="v$.form.phone.$error" #message>
                    {{ $t(v$.form.phone.$errors[0].$validator) }}
                  </template>
                </base-field-phone>
              </div>
              <div class="request-form__row">
                <label class="request-form__label">
                  {{ $t('yourCity') }}
                </label>
                <base-autocomplete
                  v-model="city"
                  :placeholder="$t('enterCity')"
                  :options="options"
                  :show-indicator="false"
                  label="fullname"
                  :has-error="v$.form.cityCode.$error"
                  @select="onSelectCity"
                  @search="onSearchCity"
                >
                  <template v-if="v$.form.cityCode.$error" #message>
                    {{ $t('requiredField') }}
                  </template>
                </base-autocomplete>
              </div>
              <div class="request-form__row">
                <label class="request-form__label">
                  {{ $t('requestSubject') }}
                </label>
                <base-field-select
                  v-model="type"
                  :placeholder="$t('chooseFromList')"
                  :options="optionTypes"
                  :show-indicator="false"
                  label="title"
                  :has-error="v$.form.topicCode.$error"
                  @select="onSelectType"
                >
                  <template v-if="v$.form.topicCode.$error" #message>
                    {{ $t('requiredField') }}
                  </template>
                </base-field-select>

                <div v-if="type && type.description" class="base-text" v-html="type.description" />
              </div>
              <div class="request-form__row">
                <label class="request-form__label">
                  {{ $t('contractNumber') }}
                </label>
                <base-input
                  v-model="form.numberContract"
                  :placeholder="$t('enterNumber')"
                  :has-error="v$.form.numberContract ? v$.form.numberContract.$error : false"
                >
                  <template
                    v-if="v$.form.numberContract ? v$.form.numberContract.$error : false"
                    #message
                  >
                    {{ $t('requiredField') }}
                  </template>
                </base-input>
              </div>
              <div class="request-form__row">
                <label class="request-form__label">
                  {{ $t('comment') }}
                </label>
                <base-text-area v-model="form.message" :placeholder="$t('enterText')" />
              </div>
              <div class="request-form__divider">
                <div>
                  <base-checkbox v-model="privacyPolicy">
                    <span
                      class="step-send-form__link"
                      v-html="$t('requestCall.confirmationOfAgreement')"
                    />
                  </base-checkbox>
                  <div v-if="v$.privacyPolicy.$error" class="form__has-error">
                    {{ $t('requiredField') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="base-modal__footer base-modal__footer--center">
            <p v-if="errorMessage" class="request-form__error error--text">
              {{ errorMessage }}
            </p>
            <base-button type="submit" primary :disabled="sendForm">
              {{ $t('submit') }}
            </base-button>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <div class="base-modal__container">
        <div class="base-modal__header">
          <base-spacer />
          <div class="base-modal__action">
            <button type="button" @click="onClose">
              <icon-close :width="24" :height="24" />
            </button>
          </div>
        </div>
        <div class="base-modal__content">
          <h2 class="request-form__sending-message">
            {{ callbackMessage }}
          </h2>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import autocompleteCityMixin from '@/components/mixins/autocompleteCity.mixin';
import { reachGoal } from '@/plugins/ym-plugin';
import { addedGAEvent } from '@/utils/google-analytic';
import { validPhone } from '@/utils/validators.default.utils';
import { dateMask } from '@/constants/mask';
import BaseSpacer from '@/components/BaseComponents/BaseSpacer.vue';
import BaseButton from '@/components/BaseComponents/BaseButton.vue';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox.vue';
import BaseFieldPhone from '@/components/BaseComponents/BaseFieldPhone.vue';
import BaseTextArea from '@/components/BaseComponents/BaseTextArea.vue';
import BaseFieldSelect from '@/components/BaseComponents/BaseFieldSelect.vue';
import BaseInput from '@/components/BaseComponents/BaseInput.vue';
import BaseAutocomplete from '@/components/BaseComponents/BaseAutocomplete.vue';
import IconClose from '@/components/icon-svg/IconClose.vue';
import moment from 'moment';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { cdekConsoleError } from '@/utils/console-wrapper';
import useSettings from '@/composables/use-settings';

export default {
  components: {
    IconClose,
    BaseSpacer,
    BaseButton,
    BaseCheckbox,
    BaseFieldPhone,
    BaseTextArea,
    BaseFieldSelect,
    BaseInput,
    BaseAutocomplete,
  },
  mixins: [autocompleteCityMixin],
  setup() {
    const { getSettingsGroup } = useSettings();

    const phoneParams = getSettingsGroup('phoneParams');

    return { v$: useVuelidate(), phoneParams };
  },
  data() {
    return {
      modalName: 'requestCall',
      sendForm: false,
      sended: false,
      privacyPolicy: false,
      city: null,
      type: null,
      options: [],
      dateMask,
      optionTypes: [
        {
          type: 'TYPE_COURIER',
          title: this.$t('typeCourier'),
          description: this.$t('requestCall.descriptionTypeCourier'),
        },
        {
          type: 'TYPE_TRACKING',
          title: this.$t('typeTracking'),
          description: this.$t('requestCall.descriptionTypeTracking'),
        },
        { type: 'TYPE_COOPERATION', title: this.$t('typeCooperation') },
        {
          type: 'TYPE_MUTUAL_SETTLEMENT',
          title: this.$t('typeMutualSettlement'),
          description: this.$t('requestCall.descriptionTypeMutualSettlement'),
        },
        {
          type: 'TYPE_OTHER',
          title: this.$t('typeOther'),
          description: this.$t('requestCall.descriptionTypeOther'),
        },
      ],
      errorMessage: '',
      form: {
        name: null,
        phone: null,
        topicCode: null,
        cityCode: null,
        numberContract: null,
        message: '',
      },
      formDocOrigins: {
        periodAll: false,
        period: [false, false, false, false],
        year: moment().format('YYYY'),
        email: '',
        from: '',
        to: '',
        radiobutton: '0',
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        phone: {
          required,
          phoneValid: validPhone,
        },
        topicCode: {
          required,
        },
        cityCode: {
          required,
        },
      },
      privacyPolicy: {
        requiredBool: (value) => value,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentLocaleISO: 'currentLocaleISO',
    }),
    codes() {
      return this.phoneParams.filter(
        (p) =>
          p.country.toLowerCase() === 'ru' ||
          p.country.toLowerCase() === 'kz' ||
          p.country.toLowerCase() === 'by',
      );
    },
    callbackMessage() {
      return this.hasTimeMessage(this.form.topicCode)
        ? this.$t('thankYouWeWillContactYou')
        : this.$t('phoneRequestSuccessMessage');
    },
  },
  methods: {
    onSearchCity: debounce(
      function debouncedOnSearchCity(search, loading) {
        return this.searchCity(search, loading).then((res) => (this.options = res));
      },
      800,
      { leading: true },
    ),
    searchCity(text, lodaing) {
      return this.autocompleteCity(text, this.currentLocaleISO, lodaing);
    },
    onSelectCity(city) {
      this.form.cityCode = city.cdek_id;
    },
    onSelectType(option) {
      this.form.topicCode = option.type;
      this.clearFormDocOrigins();
    },
    async onSubmit() {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.sendForm) {
        this.sendForm = true;
        this.errorMessage = '';
        const form = {
          ...this.form,
        };
        this.sended = false;

        try {
          const { data } = await this.$graphqlApi.sendCallback(form);
          if (data.sendCallback.success) {
            reachGoal('call_form');
            reachGoal('zakazZvonka');
            addedGAEvent('zakazZvonka');
            this.sended = true;
            this.errorMessage = '';
          } else {
            this.errorMessage =
              data.sendCallback.errors && data.sendCallback.errors.length
                ? data.sendCallback.errors[0].message
                : '';
          }
        } catch (e) {
          cdekConsoleError(e);
        } finally {
          this.sendForm = false;
        }
      }
    },
    onClose() {
      this.$modal.hide(this.modalName);
    },
    clearFormDocOrigins() {
      this.formDocOrigins = {
        periodAll: false,
        period: [false, false, false, false],
        year: moment().format('YYYY'),
        from: '',
        to: '',
        radiobutton: '0',
        email: '',
      };
    },
    onChangeAll() {
      if (this.formDocOrigins.periodAll) {
        this.formDocOrigins.period = [false, false, false, false];
      }
    },
    getMessageByPeriod() {
      const text = 'Документы необходимы за период(ы):';
      const { year } = this.formDocOrigins;
      const period1 = 'Первый квартал';
      const period2 = 'Второй квартал';
      const period3 = 'Третий квартал';
      const period4 = 'Четвертый квартал';
      const fileredPeriod = this.formDocOrigins.period.filter((boolean) => boolean);
      if (this.formDocOrigins.periodAll || fileredPeriod.length === 4) {
        return `${text} Все периоды Документы необходимы за год: ${year}`;
      }
      if (!this.formDocOrigins.periodAll) {
        const periods = [];
        if (this.formDocOrigins.period[0]) {
          periods.push(period1);
        }
        if (this.formDocOrigins.period[1]) {
          periods.push(period2);
        }
        if (this.formDocOrigins.period[2]) {
          periods.push(period3);
        }
        if (this.formDocOrigins.period[3]) {
          periods.push(period4);
        }

        return `${text} ${periods.join(', ')} Документы необходимы за год: ${year}`;
      }
    },
    getMessageByDate() {
      return `Документы необходимы за промежуток c ${this.formDocOrigins.from} по ${this.formDocOrigins.to}`;
    },
    getMessage() {
      return this.formDocOrigins.radiobutton === '0'
        ? this.getMessageByPeriod()
        : this.getMessageByDate();
    },
    click() {
      reachGoal('call');
    },
    hasTimeMessage(type) {
      return type === 'TYPE_OTHER' || type === 'TYPE_TRACKING' || type === 'TYPE_COURIER';
    },
  },
};
</script>
<style lang="scss">
.request-call-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-call-modal__content {
  margin: auto;
  width: 98%;
  max-width: 625px;
}
</style>
<style lang="scss" scoped>
$bgDivider: $GrayBTN;

.request-form {
  &__label {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 8px;
    display: block;
  }

  &__row {
    margin-bottom: 16px;
  }

  .form__radio {
    margin-bottom: 8px;
  }

  &__divider {
    border-top: 1px solid $bgDivider;
    border-bottom: 1px solid $bgDivider;
    margin-left: -10px;
    margin-right: -10px;
    padding: 32px 10px;
    margin-bottom: 8px;

    @media (min-width: $tablet) {
      margin-left: 0;
      margin-right: 0;
      padding: 32px 0;
    }
  }

  &__error {
    margin-bottom: 16px;
  }

  &__sending-message {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
