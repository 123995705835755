import libphonenumber from 'libphonenumber-js/max';

export function checkObjectIfSelect(isSelect: boolean) {
  return function carriedCheckObjectIfSelect(object: unknown) {
    return !isSelect || !!object;
  };
}

export function checkStringIfSelect(isSelect: boolean) {
  return function carriedCheckStringIfSelect(str: string) {
    return !isSelect || (str && str.length > 0);
  };
}

export function validPhone(value: string) {
  if (!value) return true;
  try {
    const res = libphonenumber(value);
    if (!res) return false;
    return res.isValid();
  } catch (e) {
    return false;
  }
}

export function validPhoneTypeMobile(value: string) {
  if (!value) return true;
  try {
    const res = libphonenumber(value);
    if (!res) return false;
    return res.getType() === 'MOBILE';
  } catch (e) {
    return false;
  }
}
