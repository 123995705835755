<template>
  <div class="help-customer-container-view-contact">
    <div ref="header" class="help-customer-container-view-contact__header">
      <button class="help-customer-container-view-contact__back" type="button" @click="onBack">
        <icon-arrow />
      </button>
      <div class="help-customer-container-view-contact__title">
        {{ widget.allQuestionTitleBack }}
      </div>
    </div>
    <div ref="box" class="help-customer-container-view-contact__box">
      <div ref="scroll" class="help-customer-container-view-contact__scroll">
        <div class="help-customer-container-view-contact__content">
          <div v-if="widget.allQuestionActive" class="help-customer-container-view-contact__item">
            <div class="help-customer-container-view-contact__item-icon">
              <img v-lazy="widget.allQuestionImg" />
            </div>
            <div class="help-customer-container-view-contact__item-content">
              <base-link :href="widget.allQuestionsLink">
                {{ widget.allQuestionTitle }}
              </base-link>
            </div>
          </div>
          <div v-if="widget.callCenterActive" class="help-customer-container-view-contact__item">
            <div class="help-customer-container-view-contact__item-icon">
              <img v-lazy="widget.callCenterImg" />
            </div>
            <div class="help-customer-container-view-contact__item-content">
              <div class="help-customer-container-view-contact__item-title">
                {{ widget.callCenterTitle }}
              </div>
              <div class="help-customer-container-view-contact__item-subject">
                {{ widget.callCenterDescription }}
              </div>
              <base-link
                :href="widget.callCenterTitle"
                class="help-customer-container-view-contact__item-button"
              >
                {{ widget.callCenterButtonName }}
              </base-link>
            </div>
          </div>
          <div v-if="widget.callActive" class="help-customer-container-view-contact__item">
            <div class="help-customer-container-view-contact__item-icon">
              <img v-lazy="widget.callImg" />
            </div>
            <div class="help-customer-container-view-contact__item-content">
              <div class="help-customer-container-view-contact__item-title">
                {{ widget.callTitle }}
              </div>
              <div class="help-customer-container-view-contact__item-subject">
                {{ widget.callDescription }}
              </div>
              <button
                type="button"
                class="help-customer-container-view-contact__item-button"
                @click="onOpenCall"
              >
                {{ widget.callButtonName }}
              </button>
            </div>
          </div>
          <div v-if="widget.mailActive" class="help-customer-container-view-contact__item">
            <div class="help-customer-container-view-contact__item-icon">
              <img v-lazy="widget.mailImg" class="help-customer-container-view-contact__mail" />
            </div>
            <div class="help-customer-container-view-contact__item-content">
              <div class="help-customer-container-view-contact__item-title">
                {{ widget.mailTitle }}
              </div>
              <div class="help-customer-container-view-contact__item-subject">
                {{ widget.mailDescription }}
              </div>
              <a :href="widget.mailLink" class="help-customer-container-view-contact__item-button">
                {{ widget.mailButtonName }}
              </a>
            </div>
          </div>
          <div v-if="widget.feedbackActive" class="help-customer-container-view-contact__item">
            <div class="help-customer-container-view-contact__item-icon">
              <img v-lazy="widget.feedbackImg" />
            </div>
            <div class="help-customer-container-view-contact__item-content">
              <div class="help-customer-container-view-contact__item-title">
                {{ widget.feedbackTitle }}
              </div>
              <div class="help-customer-container-view-contact__item-subject">
                {{ widget.feedbackDescription }}
              </div>
              <button
                type="button"
                class="help-customer-container-view-contact__item-button"
                @click="openFeedback"
              >
                {{ widget.feedbackButtonName }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import BaseLink from '@/components/BaseComponents/BaseLink.vue';
import RequestCallModal from '../Modal/RequestCallModal/RequestCallModal.vue';

export default {
  name: 'HelpCustomerContainerViewContact',
  components: {
    IconArrow: defineAsyncComponent(() => import('../icon-svg/IconArrow.vue')),
    BaseLink,
  },
  props: {
    opened: Boolean,
    widget: { type: Object, required: true },
  },
  emits: ['back'],
  watch: {
    opened(val) {
      this.toggle(val);
    },
  },
  mounted() {
    this.toggle(this.opened);
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
    setScrollHeader() {
      const styleHeader = window.getComputedStyle(this.$refs.header);
      const heightHeader = parseFloat(styleHeader.height);
      this.$refs.box.style.paddingTop = `${heightHeader}px`;
      this.$refs.scroll.style.maxHeight = '500px';
    },
    resetScrollHeader() {
      this.$refs.scroll.style.height = 'auto';
      this.$refs.header.removeAttribute('style');
      this.$refs.box.removeAttribute('style');
    },
    toggle(val) {
      if (val) {
        this.setScrollHeader();
      } else {
        this.resetScrollHeader();
      }
    },
    openFeedback() {
      this.$modal.show({ component: RequestCallModal });
    },
    onOpenCall() {
      window.open(this.widget.callLink, '', 'resizable,scrollbars,status,width=300px,height=300px');
    },
  },
};
</script>

<style scoped lang="scss">
@import './variables';

.help-customer-container-view-contact {
  width: 100%;
  height: 100%;
  position: relative;

  &__header {
    padding: 15px 25px 10px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid $baseBorderColor;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  &__back {
    font-weight: bold;
    padding: 0 16px 0 0;
    outline: none;
    line-height: 1;
    margin-top: 4px;
  }

  &__box {
    height: 100%;
  }

  &__scroll {
    overflow-y: auto;
    padding: 8px 24px;
  }

  &__item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 25px;
  }

  &__item-icon {
    margin-right: 16px;
    width: 25px;
  }

  &__item-title {
    font-size: 16px;
    line-height: 19px;
  }

  &__item-subject {
    color: #959ba4;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__item-button {
    background-color: #00b42c;
    color: white;
    border-radius: 4px;
    padding: 12px 24px;
    text-decoration: none;
    outline: none;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    min-width: 140px;
  }

  &__mail {
    width: 100%;
  }
}
</style>
