<template>
  <div ref="toggle" :class="classesContainer" style="position: relative">
    <base-input
      :id="id"
      ref="autocomplete"
      :model-value="query"
      class="base-dropdown-field"
      :placeholder="placeholder"
      :required="required"
      :has-error="hasError"
      :message-error="messageError"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :additional-info="additionalInfo"
      :old="false"
      role="none"
      :icon-left="true"
      :loading="loading"
      @keydown-down="down"
      @keydown-up="up"
      @keydown-enter="hit"
      @keydown-esc="reset"
      @blur="blur"
      @focus="focus"
      @update:model-value="update"
      @clear="clearValue"
    >
      <template #message>
        <slot name="message" />
      </template>
      <template v-if="needMagnifier" #icon>
        <button type="button" class="base-icon-button" tabindex="-1" @click="onClickIcon">
          <icon-magnifier-thin :fill="$uiBaseColor.base" :width="22" :height="22" />
        </button>
      </template>
    </base-input>
    <div
      v-if="displayDropdownMenu"
      ref="baseDropdownMenu"
      v-append-to-body
      class="base-dropdown-menu"
      role="listbox"
    >
      <div
        v-for="(item, $index) in items"
        :key="getKeyList($index)"
        role="option"
        class="base-dropdown-menu__dropdown-item"
        :class="activeClass($index)"
        :tabindex="tabindex($index)"
        :aria-selected="$index === current ? true : null"
        @mousedown="hit"
        @mousemove="setActive($index)"
      >
        <slot name="dropdown-item" :item="item">
          {{ getLabel(item) }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <template v-slot:dropdown-item="{ item }">
 * <div>
 * {{ item.name }}
 * </div>
 * <b>{{ item.fullname }}</b>
 * </template>
 */
import IconMagnifierThin from '../icon-svg/IconMagnifierThin.vue';
import basePropsMixin from './mixins/base-props.mixin';
import AutocompleteMixin from '../Autocomplete/autocomplete.mixin';
import pointerScroll from './mixins/pointerScroll';
import BaseInput from './BaseInput.vue';

export default {
  name: 'BaseAutocomplete',
  components: { BaseInput, IconMagnifierThin },
  mixins: [basePropsMixin, AutocompleteMixin, pointerScroll],
  props: {
    needMagnifier: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    classesContainer() {
      return {
        'base--opened': this.displayDropdownMenu,
      };
    },
  },
  methods: {
    clearValue() {
      if (this.disabled || this.readonly) return;
      this.clear();
      setTimeout(() => {
        this.$refs.autocomplete.$refs.field.focus();
      });
    },
    onClickIcon() {
      this.$refs.autocomplete.$refs.field.focus();
    },
  },
};
</script>
