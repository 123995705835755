<template>
  <div v-if="isRatingBlockVisible" class="help-customer-rating">
    <div class="help-customer-rating__success-close-icon" @click="clickSuccessClose">
      <close-icon />
    </div>

    <template v-if="showSuccess">
      <div class="help-customer-rating__success">
        <div class="help-customer-rating__success-container">
          <div class="help-customer-rating__success-text">
            {{ $t('ratingProduct.thank') }}
          </div>
          <div class="help-customer-rating__success-subtext">
            {{ $t('ratingProduct.feedback') }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="help-customer-rating__title">
        {{ $t('ratingProduct.what') }}
      </div>
      <form class="form" @submit.prevent="send">
        <div>
          <div class="help-customer-rating__stars">
            <div
              v-for="n in $options.STARS_LIMIT"
              :key="n"
              class="help-customer-rating__star"
              @click="update(n)"
            >
              <div>
                <star-icon
                  class="help-customer-rating__star-icon"
                  :class="{ 'help-customer-rating__star-icon-active': activeIcons[n - 1] }"
                />
              </div>
              <div class="help-customer-rating__star-text">{{ n }}</div>
            </div>
          </div>
          <cdek-textarea
            v-if="showComment"
            v-model.trim="comment"
            :placeholder="$t('ratingProduct.dislike')"
            maxlength="2000"
            height="2"
            class="help-customer-rating__comment"
            :valid-res="buttonDisabled && $t('requiredField')"
            @update:model-value="checkButtonDisabled"
          />

          <cdek-button
            :disabled="buttonDisabled"
            type="submit"
            small
            :loading="buttonLoading"
            class="help-customer-rating__submit"
          >
            {{ $t('ratingProduct.send') }}
          </cdek-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { cdekConsoleError } from '@/utils/console-wrapper';
import { reachGoal } from '@/plugins/ym-plugin';
import { CdekButton, CdekTextarea } from '@cdek-ui-kit/vue';
import CloseIcon from '@/components/_common/close.svg';
import { ratingCookieKey } from '@/components/HelpCustomer/consts';
import useFlomni from '@/composables/use-flomni';
import { ref } from 'vue';
import StarIcon from './icons/star.svg';

const STARS_LIMIT = 10;
const STARS_FOR_MESSAGE = 4;
const STARTS_NOT_REQUIRED_MESSAGE = 6;

export default {
  name: 'HelpCustomerRating',

  components: { CdekTextarea, CdekButton, StarIcon, CloseIcon },

  props: {
    opened: Boolean,
  },
  emits: ['close-rating'],

  STARS_LIMIT,
  ratingCookieKey,

  setup() {
    const { isReviewVisible, setRatingCookie, setIsReviewVisible } = useFlomni();

    const isRatingBlockVisible = ref(isReviewVisible.value);

    return {
      isRatingBlockVisible,
      setRatingCookie,
      setIsReviewVisible,
    };
  },

  data() {
    return {
      rating: 0,
      comment: null,
      activeIcons: () => new Array(STARS_LIMIT).fill(false),
      showComment: false,
      showSuccess: false,
      buttonLoading: false,
      buttonDisabled: true,
    };
  },

  computed: {
    ...mapState('cabinet', ['user']),
    ...mapGetters({
      userPhone: 'cabinet/getLoginPhone',
    }),
  },

  watch: {
    opened(val) {
      if (val === false) {
        this.reset();
      }
    },
  },

  methods: {
    send() {
      this.buttonLoading = true;

      if (this.rating > STARTS_NOT_REQUIRED_MESSAGE) {
        this.comment = '';
      }

      this.$restApi
        .ratingProductAdd(this.rating, this.comment, this.user?.contragent_uuid, this.userPhone)
        .then(({ success, error }) => {
          this.showSuccess = success;
          if (success) {
            this.setRatingCookie();
          }
          if (error) {
            cdekConsoleError(error);
          }
        })
        .catch((e) => {
          cdekConsoleError(e);
        })
        .finally(() => {
          this.buttonLoading = false;
          reachGoal('widget_feedback');
        });
    },
    update(starsCount) {
      this.rating = starsCount;
      this.activeIcons = new Array(STARS_LIMIT).fill(true, 0, starsCount);
      this.showComment = starsCount <= STARTS_NOT_REQUIRED_MESSAGE;
      this.buttonDisabled = !(starsCount > STARS_FOR_MESSAGE || this.comment?.length > 0);
    },
    checkButtonDisabled() {
      this.buttonDisabled =
        !(this.comment && this.comment.length > 0) && this.rating <= STARS_FOR_MESSAGE;
    },

    reset() {
      this.rating = 0;
      this.comment = null;
      this.activeIcons = new Array(STARS_LIMIT).fill(false);
      this.showComment = false;
      this.showSuccess = false;
      this.buttonLoading = false;
      this.buttonDisabled = true;
    },
    clickSuccessClose() {
      this.reset();

      this.isRatingBlockVisible = false;

      this.$emit('close-rating');
    },
  },
};
</script>

<style scoped lang="scss">
.help-customer-rating {
  position: relative;
  border-bottom: 1px solid #0000001a;
  border-radius: 10px;
  background: $Peak;
  padding: 0 16px 20px;
  margin-top: 24px;

  @include media-min-md {
    margin-top: 0;
    padding: 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  }

  &__title {
    @include headline-6;

    padding-right: 32px;
  }

  &__stars {
    display: flex;
    padding: 12px 0 16px;

    @include media-min-md {
      padding-bottom: 12px;
    }
  }

  &__star {
    display: flex;
    flex-direction: column;
    padding: 0 2px;

    &-text {
      text-align: center;

      @include caption-1;
    }

    &-icon:hover,
    &-icon-active {
      :deep(path) {
        fill: #f1b13b;
        stroke: #f1b13b;
      }
    }
  }

  &__button {
    width: 100%;
    border-radius: 10px;
    text-transform: uppercase;

    &_disabled {
      background: rgba(0, 0, 0, 0.35) !important;
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  &__success {
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &-container {
      position: relative;
    }

    &-close-icon {
      position: absolute;
      top: 0;
      right: 16px;
      cursor: pointer;
      z-index: 2;

      @include media-min-md {
        top: 12px;
      }
    }

    &-text {
      width: max-content;
      @include headline-6;
    }

    &-subtext {
      @include body-2;
    }
  }

  &__comment {
    margin-bottom: 16px;

    :deep(.cdek-textarea__tip:empty) {
      display: none;
    }

    :deep(.cdek-textarea__control) {
      background: $Peak;
      border: 1px solid #dcdbdc;
    }
  }

  &__submit {
    @include media-min-md {
      width: 123px;
    }
  }
}
</style>
