import type { ComponentPublicInstance } from 'vue';
import { createSSRApp, defineComponent, h, markRaw, reactive, createApp, ref, provide } from 'vue';
import FloatingVue from 'floating-vue';
import type { ActiveHeadEntry } from '@vueuse/head';
import { createHead } from '@vueuse/head';
import { getActiveHead } from 'unhead';
import type { Head, Unhead } from '@unhead/schema';
import 'floating-vue/dist/style.css';
import '@cdek-ui-kit/vue/style.css';
import { appearDirective } from '#root/src/directives/appear.directive';
import restApiClientPlugin from '#root/src/plugins/rest-api-client-plugin';
import graphqlApiClientPlugin from '#root/src/plugins/graphql-api-client-plugin';
import defaultColors from '#root/src/plugins/default-colors';
import emitterPlugin from '#root/src/plugins/emitter-plugin';

import { rewriteServerMetaData, updateHead } from '#root/src/utils/metaDataHelpers';
import DefaultLayout from '#root/src/layouts/DefaultLayout.vue';
import VueLazyLoad from 'vue3-lazyload';
import VueCookies from 'vue-cookies';
import { createVuexStore } from '@/store/vuex-store';
// es2015 module

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
import '../assets/style/app.scss';
import '../assets/font.css';
import '../assets/main.css';
import type { InitialSiteData } from '@/types/dto/initialSiteData';
import { clickOutsideDirective } from '@/directives/cdek-click-outside.directive';
import { appendToBodyDirective } from '@/directives/append-to-body.directive';
import testIdDirective from '@/directives/test-id.directive';
import apiClientPlugin from '@/plugins/api-client-plugin';
import type { CdekStore } from '@/types/store';
import libphonenumberPlugin from '@/plugins/libphonenumber-plugin';
import sentryPlugin from '@/plugins/sentry-plugin';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import { initYmPlugin, hitYm } from '@/plugins/ym-plugin';
import { initGtmPlugin } from '@/plugins/gtm-plugin';
import routerPushPlugin from '@/plugins/router-push-plugin';
import { registerToastification } from '@cdek-ui-kit/vue';
import { setPageContext } from '@/composables/use-page-context';
import { createPinia } from 'pinia';
import { cdekConsoleError } from '@/utils/console-wrapper';
import initMindbox from '@/plugins/mindbox';
import { useI18nHelpers } from '@/composables/use-i18n-helpers';
import { initVKAdsPlugin } from '@/plugins/vk-ads-plugin';
import { installADV } from '@/plugins/ADV-plugin';
import useCountries from '@/composables/use-countries';
import { useAutodetectCity } from '@/composables/use-autodetect-city';
import useSettings from '@/composables/use-settings';
import type { PageContext } from 'vite-plugin-ssr/types';
import { useAuth } from '@/composables/use-auth/use-auth';
import i18n from '../locales/i18n-config';

// Функция, которую я нашел в репозитории с примерами.
// Обычный Object.assign, но с типами
function objectAssign<Obj extends object, ObjAddendum>(
  obj: Obj,
  objAddendum: ObjAddendum,
): asserts obj is Obj & ObjAddendum {
  Object.assign(obj, objAddendum);
}

function getPageLayout(pageContext: PageContext) {
  const { isWebView, routeParams, urlOriginal } = pageContext;

  const locale = routeParams?.locale || '';

  if (
    isWebView ||
    urlOriginal.includes(`/profi`) ||
    urlOriginal.startsWith(`/resolver`) ||
    (locale && urlOriginal.startsWith(`/${locale}/cod-card-success`)) ||
    (locale && urlOriginal.startsWith(`/${locale}/cod-payout-check`)) ||
    pageContext.pageProps?.layout === 'info-order'
  ) {
    return EmptyLayout;
  }

  if (pageContext.exports.Layout) {
    return pageContext.exports.Layout;
  }

  return DefaultLayout;
}

async function createPageApp(
  pageContext: PageContext,
  clientOnly: boolean,
  siteData: InitialSiteData,
  runsOnClient = false,
) {
  const { Page, urlPathname, locale, authorization, isWebView, isAuthorizedUser } = pageContext;

  let rootComponent: any;
  const hasBuzzulaError = ref(false);

  const PageWithLayout = defineComponent({
    inject: ['changeRoute'],
    setup() {
      provide('hasBuzzulaError', hasBuzzulaError);
      const { autodetectCity } = useAutodetectCity();
      return { autodetectCity };
    },
    data: () => ({
      Page: markRaw(Page),
      pageProps: markRaw(pageContext.pageProps || {}),
      Layout: markRaw(getPageLayout(pageContext)),
    }),
    created() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      rootComponent = this;
    },
    mounted() {
      try {
        this.autodetectCity();
      } catch (e) {
        cdekConsoleError(e);
      }
    },
    render() {
      return h(
        this.Layout,
        {},
        {
          default: () => {
            return h(this.Page, this.pageProps);
          },
        },
      );
    },
  });

  const app = clientOnly ? createApp(PageWithLayout) : createSSRApp(PageWithLayout);

  const pinia = createPinia();
  app.use(pinia);

  const store = createVuexStore(runsOnClient);
  app.use(store);
  store.dispatch('resize/setScreenByType', pageContext.isMobile ? 'mobile' : 'desktop');

  const i18nPlugin = await i18n(pageContext.locale);
  useI18nHelpers(i18nPlugin);
  app.use(i18nPlugin);

  app.use(restApiClientPlugin, runsOnClient);
  app.use(graphqlApiClientPlugin, runsOnClient);
  app.use(apiClientPlugin, store as CdekStore);

  app.use(FloatingVue);
  app.directive('appear', appearDirective);
  app.directive('click-outside', clickOutsideDirective);
  app.directive('append-to-body', appendToBodyDirective);
  app.directive('test-id', testIdDirective);

  app.use(defaultColors);
  app.use(createHead());
  app.use(VueLazyLoad, {});
  app.use(emitterPlugin);
  app.use(VueCookies);

  const { init, getSettingsGroup } = useSettings();

  if (siteData) {
    const { countries, settings } = siteData;

    if (countries) {
      const { setDefaultCountries } = useCountries();

      setDefaultCountries(countries);
    }

    if (settings) {
      init(settings);
    }

    store.dispatch('UPDATE_SITE_DATA', { siteData, urlPathname, locale });

    if (isWebView && authorization) {
      store.commit('keycloak/setKeycloak', { access_token: authorization.replace('Bearer ', '') });
    }

    if (isAuthorizedUser) {
      store.commit('auth/setHasAuthCookie', true);
    }
  }

  const pageContextReactive = reactive(pageContext);
  app.use(routerPushPlugin, pageContextReactive, runsOnClient);
  // Там ошибка Unhead<any> | undefined не может быть использован как Unhead<any> | undefined
  // я хз че такое
  const activeHead = getActiveHead() as Unhead<any> | undefined;
  let activeEntry: ActiveHeadEntry<Head> | undefined;

  // Все управление метаданными хотим переложить на библиотеку.
  // ССР и ССГ страницы приходят с подготовленной метой. Чтобы либа нормально следила
  // За своими мета данными, нужно удалить серверные данные и с помощью либы подставить новые
  if (runsOnClient) {
    if (!window.BUZZOOLA) {
      const library = document.createElement('script');
      library.src = 'https://tube.buzzoola.com/build/buzzlibrary.js';

      library.onerror = () => {
        hasBuzzulaError.value = true;
      };

      library.onabort = () => {
        hasBuzzulaError.value = true;
      };

      document.body.appendChild(library);
    }

    const { SSO_DISABLED, VK_PIXEL_ID, enableMindbox } = getSettingsGroup('all');

    if (!SSO_DISABLED) {
      const { initAuth } = useAuth();
      const checkSSOAuth = async () => {
        const isSSOAuthenticated = await initAuth();
        if (isSSOAuthenticated) {
          store.commit('auth/setIsSsoAuth', true);
        }
        store.commit('auth/setIsSsoChecked', true);
      };

      if (urlPathname.includes('search')) {
        // FIXME: Костыль для фикса яндекс поиска, не рисуем страницу пока не проверим авторизацию
        await checkSSOAuth();
      } else {
        checkSSOAuth();
      }
    } else {
      store.commit('auth/setIsSsoChecked', true);
    }

    rewriteServerMetaData(
      i18nPlugin.global.t,
      activeHead,
      activeEntry,
      pageContext.pageProps?.metaData,
    );

    const { vfmPlugin } = await import('vue-final-modal');

    app.use(
      vfmPlugin({
        key: '$modal',
        dynamicContainerName: 'modals-container',
        componentName: 'VueFinalModal',
      }),
    );
    const Notifications = await import('@kyvg/vue3-notification');
    (store as CdekStore).$notify = Notifications.notify;
    app.use(Notifications.default);
    const { default: initAdmitad } = await import('@/utils/initAdmitad');
    initAdmitad();

    const { default: identityPersonApiPlugin } = await import(
      '@/plugins/indentity-person-api-client'
    );
    app.use(identityPersonApiPlugin, store as CdekStore);
    app.use(libphonenumberPlugin);

    installADV();
    initYmPlugin();
    initGtmPlugin();

    registerToastification(app);

    app.use(sentryPlugin);

    initMindbox(enableMindbox);

    initVKAdsPlugin(VK_PIXEL_ID);
  } else {
    // Уведомления и модалки используются только на клиенте
    // На беке из-за этого падают ворнинги о неизвестных компонентах
    // Чтобы бек работал нормально, регистрирую компоненты, на клиенте они перетруся
    app.component('Notifications', { template: '<div/>' });
    app.component('ModalsContainer', { template: '<div/>' });
    app.provide('$modal', {});
    app.provide('$sentry', {
      withScope: () => {},
      setTag: () => {},
      captureException: () => {},
    });
  }

  const changePage = (pc: PageContext) => {
    rootComponent.changeRoute(pc.urlPathname);

    Object.assign(pageContextReactive, pc);

    rootComponent.Page = markRaw(pc.Page);
    rootComponent.pageProps = markRaw(pc.pageProps || {});

    rootComponent.Layout = markRaw(getPageLayout(pc));

    activeEntry = updateHead(i18nPlugin.global.t, activeHead, activeEntry, pc.pageProps?.metaData);
    hitYm(pc.urlPathname);
  };

  const startLoading = () => {
    store.commit('loader/setIsPageLoading', true);
  };
  const stopLoading = () => {
    store.commit('loader/setIsPageLoading', false);
  };

  objectAssign(app, { changePage, startLoading, stopLoading });

  setPageContext(app, pageContextReactive);

  app.config.errorHandler = (
    err: unknown,
    instance: ComponentPublicInstance | null,
    info: string,
  ) => {
    cdekConsoleError(err, instance, info);
  };

  return app;
}

export { createPageApp };
