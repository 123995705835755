import { useStore } from 'vuex';
import useCabinetApi from '@/composables/use-cabinet-api';
import useTildaUser from '@/composables/use-tilda-user';
import { sendUserParams } from '@/plugins/ym-plugin';
import { captureClientException } from '@/utils/handleErrors/handleErrors';
import { useAuth } from '@/composables/use-auth/use-auth';
import useOrderNotification from './use-order-notification';
import useEmailOnLogin from './use-email-on-login';

interface LoadParams {
  withOrderNotification?: boolean;
}

export default function useLoadUser() {
  const store = useStore();
  const { apiClient } = useCabinetApi();
  const tildaUser = useTildaUser();
  const loadOrderNotification = useOrderNotification();
  const emailOnLogin = useEmailOnLogin();

  const { setAuthCookie, removeAuthCookie } = useAuth();

  const load = async ({ withOrderNotification = false }: LoadParams = {}) => {
    try {
      const res = await apiClient.userMe();
      const { data } = res.data;

      store.commit('cabinet/setUserProfile', data.profile);
      store.commit('cabinet/setLoginPhone', data.profile.phone);
      store.commit('cabinet/setUserContragent', data.contragent);
      store.commit('cabinet/setUserContragentUUID', data.contragent_uuid);
      store.commit('cabinet/setCdekidStatus', data.cdekid_status);
      store.commit('cabinet/setOrdersHasUpdates', data.ordersHasUpdates);
      store.commit('cabinet/touchLastDateIfUserLoaded');

      setAuthCookie();

      emailOnLogin.check();

      tildaUser.saveLS({
        auth: true,
        cdekId: data.contragent?.cdekId,
      });

      sendUserParams({ cdek_id: store.getters.isActiveCdekId });

      if (withOrderNotification) {
        loadOrderNotification();
      }
    } catch (error) {
      removeAuthCookie();
      tildaUser.removeLS();
      captureClientException({ place: 'login-load-use', error });
    }
  };

  return {
    load,
  };
}
