<template>
  <div
    v-click-outside="{
      exclude: ['button'],
      handler: closeDropdown,
    }"
    class="switch-locale"
  >
    <div class="switch-locale__content">
      <button ref="button" type="button" class="switch-locale__toggle" @click="toggle">
        <component :is="currentFlag" v-if="currentFlag" class="switch-locale__flag" />
        <span class="switch-locale__lang">
          {{ selectedLang }}
        </span>
        <span class="switch-locale__icon">
          <icon-arrow
            class="switch-locale__icon-arrow"
            :color="colorArrow"
            :direction="directionArrow"
            :width="12"
            :height="8"
          />
        </span>
      </button>
    </div>
    <transition name="dropdown">
      <div v-if="open" class="switch-locale__dropdown">
        <a
          v-for="(locale, index) of locales"
          :key="index"
          class="switch-locale__dropdown-item"
          :class="{
            'switch-locale__dropdown-item--active': locale.code === currentLocale,
          }"
          @click="onChangeLang(locale.code)"
        >
          <component
            :is="getFlag(locale.code)"
            v-if="getFlag(locale.code)"
            class="switch-locale__flag"
          />
          {{ locale.name }}
        </a>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref } from 'vue';
import { usePageContext } from '#root/src/composables/use-page-context';

import kz from '@/static/flags/flag_kz.svg';
import am from '@/static/flags/flag_am.svg';
import kg from '@/static/flags/flag_kg.svg';
import by from '@/static/flags/flag_by.svg';
import ru from '@/static/flags/flag_ru.svg';
import ge from '@/static/flags/flag_ge.svg';
import uz from '@/static/flags/flag_uz.svg';
import az from '@/static/flags/flag_az.svg';
import IconArrow from '@/components/icon-svg/IconArrow.vue';
import { $uiBaseColor } from '@/constants/default-colors';

import useSiteInfo from '@/composables/use-site-info';
import useSettings from '@/composables/use-settings';

const { locale } = useI18n({ useScope: 'global' });
const flags = {
  kz,
  am,
  kg,
  by,
  ru,
  uz,
  ge,
  az,
};
const pageContext = usePageContext();

const { isAmSite, isKzSite, currentLocale } = useSiteInfo();

const open = ref(false);
const closeDropdown = () => {
  open.value = false;
};
const toggle = () => {
  open.value = !open.value;
};

const onChangeLang = async (newLocale: string) => {
  const { urlPathname } = pageContext;
  const urlWithoutPrefix = urlPathname.replace(`/${locale.value}`, '');
  window.location.href = `/${newLocale}${urlWithoutPrefix}`;
};

const { getSettingsGroup } = useSettings();

const locales = getSettingsGroup('locales');

const selectedLang = computed(() => {
  return currentLocale.value === 'zh' ? '\u4e2d\u6587' : currentLocale.value;
});

const colorArrow = computed(() => {
  return open.value ? $uiBaseColor.primaryColor : $uiBaseColor.darkGray;
});
const directionArrow = computed(() => {
  return open.value ? 'top' : 'down';
});

const sendMindBoxData = () => {
  if (isAmSite.value || isKzSite.value) {
    const { mindbox } = window;

    if (mindbox) {
      const sitePrefix = isKzSite.value ? 'WebsiteKZ' : 'WebsiteAM';

      mindbox('async', {
        operation: `${sitePrefix}.LanguageCommunication`,
        data: {
          customer: {
            customFields: {
              languageCommunication: currentLocale.value,
            },
          },
        },
      });
    }
  }
};

onMounted(() => {
  sendMindBoxData();
});

const currentFlag = computed(() => {
  return flags[currentLocale.value as keyof typeof flags];
});

const getFlag = (code: string) => {
  return flags[code as keyof typeof flags];
};
</script>

<style lang="scss">
.switch-locale {
  display: flex;
  position: relative;
  align-items: center;

  &__toggle {
    display: flex;
    align-items: center;
    padding: 0 4px;
    text-transform: capitalize;
    outline: none;
  }

  &__icon {
    margin-left: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-arrow {
    width: 6px;
    height: 10px;
    transition: $primary-transition;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: -30px;
    padding: 8px 0;
    border: 1px solid #e2e5ec;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 2;

    @media (min-width: $desktop) {
      right: -4px;
    }
  }

  &__dropdown-item {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    color: $base-color;

    &--active {
      color: $primary-base;
    }
  }

  &__flag {
    margin-top: -2px;
    margin-right: 16px;
    border-radius: 3px;

    @media (min-width: $desktop) {
      margin-right: 8px;
    }
  }

  &__lang {
    color: $black-dark;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-right: 2px;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition:
    opacity $primary-transition,
    right $primary-transition;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}
</style>
