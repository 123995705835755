import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type MainState from '@/types/store';
import type { AuthState } from '@/types/store/auth';

const initialKeycloak = (): AuthState => ({
  impersonation: null,
  isSsoAuth: false,
  isSsoChecked: false,
  hasAuthCookie: false,
});

const state = (): AuthState => initialKeycloak();
const mutations: MutationTree<AuthState> = {
  setImpersonation(state, payload) {
    state.impersonation = payload;
  },
  setIsSsoAuth(state, payload: boolean) {
    state.isSsoAuth = payload;
  },
  setIsSsoChecked(state, payload: boolean) {
    state.isSsoChecked = payload;
  },
  setHasAuthCookie(state, payload: boolean) {
    state.hasAuthCookie = payload;
  },
};

const actions: ActionTree<AuthState, MainState> = {
  removeImpersonation({ dispatch, commit, rootGetters }, alsoLogoutFromCabinet = false) {
    commit('setImpersonation', null);

    if (alsoLogoutFromCabinet) {
      dispatch('cabinet/logoutFromCabinet', null, { root: true }).then(() => {
        if (window.location.pathname.match(/^\/[a-z]{2}\/cabinet\//)) {
          // Редирект на страницу авторизации (/ru/login)
          let redirect = window.location.pathname + window.location.search + window.location.hash;
          // в base64 чтобы нормальная строка была в гет параметре
          redirect = btoa(redirect);

          const url = new URL(window.location.origin);
          url.pathname = `/${rootGetters.currentLocaleISO}/cabinet/auth`;
          url.searchParams.set('redirect', redirect);

          window.location.replace(url.href);
          return;
        }

        window.location.pathname = `/${rootGetters.currentLocaleISO}/`;
      });
    }
  },
};

const getters: GetterTree<AuthState, MainState> = {
  getImpersonation(state) {
    return state.impersonation;
  },
  getImpersonationToken(state) {
    return state.impersonation?._t;
  },
  isImpersonationTokenExpired(state) {
    return () => {
      const et = state.impersonation?._et;
      if (!et) {
        return true;
      }

      const nowTS = Math.round(new Date().getTime() / 1000);
      // TODO: fix type et
      const accessTokenExpiredAtTS = Math.round(new Date(`${et}`).getTime() / 1000) - 10;

      return accessTokenExpiredAtTS < nowTS;
    };
  },
  enabledLK() {
    // на данный момент используется на всех сайтах
    // в будущем будем добавлять исключение !rootGetters.isEnSite
    return true;
  },
};

export const authModule: Module<AuthState, MainState> = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
