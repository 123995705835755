import { reachGoal } from '@/plugins/ym-plugin';

const tildaTriggers = [
  {
    // Страница онлайн магазинам, казхский сайт, кз/ру локаль (этот айдишник одинаковый), заключить договор на баннере
    query: '[data-lid="1660034890102"]',
    handler: () => reachGoal('im_dogovor'),
  },

  {
    // Страница онлайн магазинам, казхский сайт, кз локаль, заключить договор под таблицей
    query: '[data-tilda-event-name="/tilda/click/rec546735897/button1475077923649"]',
    handler: () => reachGoal('im_dogovor'),
  },
  {
    // Страница онлайн магазинам, казхский сайт, кз локаль, заключить договор последняя
    query: '[data-tilda-event-name="/tilda/click/rec546735900/button1475077923649"]',
    handler: () => reachGoal('im_dogovor'),
  },

  {
    // Страница онлайн магазинам, казхский сайт, ру локаль, заключить договор под таблицей
    query: '[data-tilda-event-name="/tilda/click/rec551400770/button1475077923649"]',
    handler: () => reachGoal('im_dogovor'),
  },
  {
    // Страница онлайн магазинам, казхский сайт, ру локаль, заключить договор последняя
    query: '[data-tilda-event-name="/tilda/click/rec551400773/button1475077923649"]',
    handler: () => reachGoal('im_dogovor'),
  },

  {
    // Страница contract, казхский сайт, ру локаль, перейти к анкете в карточке
    query: '[data-elem-id="1663599749578"] a',
    handler: () => reachGoal('contract_redirect'),
  },
  {
    // Страница contract, казхский сайт, ру/кз локаль, заполнить анкету в синей карточке
    query: '[data-elem-id="1663766563082"] a',
    handler: () => reachGoal('contract_redirect'),
  },
  {
    // Страница contract, казхский сайт, ру локаль, ссылка в частозадаваемых вопросах
    query: '#accordion3_551401254 a',
    handler: () => reachGoal('contract_redirect'),
  },
  {
    // Страница contract, казхский сайт, ру/кз локаль, заполнить анкету в банере
    query: '[data-elem-id="1663597949993"] a',
    handler: () => reachGoal('contract_redirect'),
  },

  {
    // Страница contract, казхский сайт, кз локаль, перейти к анкете в карточке
    query: '[data-elem-id="1676312728049"] a',
    handler: () => reachGoal('contract_redirect'),
  },
  {
    // Страница contract, казхский сайт, кз локаль, ссылка в частозадаваемых вопросах
    query: '#accordion3_546736178 a',
    handler: () => reachGoal('contract_redirect'),
  },
];

export const handleTildaYm = (): void => {
  tildaTriggers.forEach((el) => {
    const button = document.querySelector(el.query);

    if (button) {
      button.addEventListener('click', el.handler);
    }
  });
};

export const removeTildaYm = (): void => {
  tildaTriggers.forEach((el) => {
    const button = document.querySelector(el.query);

    if (button) {
      button.removeEventListener('click', el.handler);
    }
  });
};
