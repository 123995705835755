<template>
  <div
    ref="toggle"
    v-click-outside="{
      exclude: ['button'],
      handler: close,
    }"
    :class="classesContainer"
    style="position: relative"
  >
    <base-input
      :id="id"
      ref="autocomplete"
      v-model="query"
      class="base-dropdown-field"
      :placeholder="placeholder"
      :required="required"
      :has-error="hasError"
      :message-error="messageError"
      :name="name"
      :disabled="disabled"
      :additional-info="additionalInfo"
      :old="old"
      :order="order"
      :icon-right="true"
      :readonly="!filterable"
      @keydown-down="down"
      @keydown-up="up"
      @keydown-enter="hit"
      @focus="onFocus"
      @click="onClick"
    >
      <template #message>
        <slot name="message" />
      </template>
      <template #icon>
        <button
          ref="button"
          type="button"
          class="base-icon-button"
          :disabled="disabled"
          @click="onToggle"
        >
          <icon-arrow :color="color" :height="10" :direction="direction" />
        </button>
      </template>
    </base-input>

    <div
      v-if="displayDropdownMenu"
      ref="baseDropdownMenu"
      v-append-to-body
      class="base-dropdown-menu"
      role="listbox"
    >
      <div
        v-for="(item, $index) in filteredOptions"
        :key="getKeyList($index)"
        role="option"
        class="base-dropdown-menu__dropdown-item"
        :class="classesDropdownItem($index)"
        :tabindex="tabindex($index)"
        :aria-selected="$index === current ? true : null"
        @click="hit($index)"
        @touchstart="hit($index)"
      >
        <slot name="dropdown-item" :item="item">
          {{ getLabel(item) }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import basePropsMixin from '@/components/BaseComponents/mixins/base-props.mixin';
import pointerScroll from '@/components/BaseComponents/mixins/pointerScroll';
import baseDropdownMixin from '@/components/BaseComponents/mixins/base-droppown.mixin';
import IconArrow from '@/components/icon-svg/IconArrow.vue';
import BaseInput from '@/components/BaseComponents/BaseInput.vue';

export default {
  name: 'BaseFieldSelect',
  components: { IconArrow, BaseInput },
  mixins: [basePropsMixin, pointerScroll, baseDropdownMixin],

  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    filterBy: {
      type: Function,
      default(option, label, search) {
        return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
      },
    },
    filter: {
      type: Function,
      default(options, search) {
        return options.filter((option) => {
          let label = this.getLabel(option);
          if (typeof label === 'number') {
            label = label.toString();
          }
          return this.filterBy(option, label, search);
        });
      },
    },
  },
  emits: ['input', 'update:modelValue', 'focus', 'change', 'blur', 'select'],
  data() {
    return {
      query: '',
      opened: false,
      selected: -1,
      filtering: false,
    };
  },
  computed: {
    classesContainer() {
      return {
        'base--opened': this.opened,
      };
    },
    direction() {
      return this.opened ? 'top' : 'down';
    },
    color() {
      return this.opened ? this.$uiBaseColor.base : this.$uiBaseColor.darkGray;
    },
    filteredOptions() {
      if (this.filterable && this.filtering) {
        return this.filter(this.items, this.query);
      }
      return this.items;
    },
  },
  watch: {
    options(val) {
      this.setItems(val);
    },
    modelValue(val) {
      this.setQuery(val);
      this.filtering = false;
    },
    query(value) {
      if (this.opened) {
        this.filtering = this.filterable && value && value.trim();
      }
    },
  },
  mounted() {
    this.setItems(this.options);
    if (this.modelValue) {
      this.setQuery(this.modelValue);
      this.setSelected(this.modelValue);
    }
  },
  methods: {
    classesDropdownItem($index) {
      return {
        ...this.activeClass($index),
        selected: this.selected === $index,
      };
    },
    updateDisplayDropdownMenu() {
      this.displayDropdownMenu = this.opened;
    },
    onToggle() {
      this.opened ? this.close() : this.open();
    },
    onClick() {
      if (this.focused) {
        this.onToggle();
      }
    },
    hit($index) {
      this.onHit(this.filteredOptions[$index]);
    },
    onHit(value) {
      this.select(value);
      this.onBlur();
    },
    onFocus() {
      this.open();
    },
    onBlur() {
      this.close();
    },
    close() {
      this.opened = false;
      this.updateDisplayDropdownMenu();
    },
    open() {
      this.opened = true;
      this.updateDisplayDropdownMenu();
    },
    select(value) {
      if (this.getValue(value)) {
        this.setSelected(this.getValue(value));
        this.setQuery(value);
        this.$emit('select', this.getValue(value));
        this.$emit('update:modelValue', this.getValue(value));
      }
    },
    setQuery(value) {
      setTimeout(() => {
        if (['string', 'number'].indexOf(typeof value) !== -1) {
          this.query = value;
        } else {
          this.query = this.getLabel(value);
        }
      });
    },
    setSelected(value) {
      this.selected = this.getSelectedIndex(value);
    },
    getSelectedIndex(value) {
      if (['string', 'number'].indexOf(typeof value) !== -1) {
        return this.filteredOptions.findIndex((item) => this.getLabel(item) === value);
      }
      return this.filteredOptions.findIndex((item) => this.getLabel(item) === this.getLabel(value));
    },
  },
};
</script>
