<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8097 21.878L17.4622 16.5317C18.8967 14.8829 19.7651 12.7317 19.7651 10.3805C19.7651 5.20488 15.5544 1 10.3826 1C5.20581 1 1 5.20976 1 10.3805C1 15.5512 5.21069 19.761 10.3826 19.761C12.7343 19.761 14.886 18.8927 16.5352 17.4585L21.8827 22.8049C22.0095 22.9317 22.1803 23 22.3462 23C22.5121 23 22.6829 22.9366 22.8097 22.8049C23.0634 22.5512 23.0634 22.1317 22.8097 21.878ZM2.31249 10.3805C2.31249 5.93171 5.9328 2.31707 10.3777 2.31707C14.8275 2.31707 18.4429 5.93659 18.4429 10.3805C18.4429 14.8244 14.8275 18.4488 10.3777 18.4488C5.9328 18.4488 2.31249 14.8293 2.31249 10.3805Z"
      :fill="fill"
      :stroke="fill"
      stroke-width="0.4"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMagnifierThin',
  props: {
    fill: {
      type: String,
      default() {
        return '#232323';
      },
    },
  },
};
</script>

<style scoped></style>
