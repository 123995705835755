export const getHeadElement = () => {
  return document.getElementsByTagName('head')[0];
};

interface IScriptProps {
  text?: string;
  id?: string;
  defer?: boolean;
  src?: string;
  onload?: () => void;
}
export const createScriptElement = (props: IScriptProps = {}) => {
  return Object.assign(document.createElement('script'), props);
};

export const getBodyElement = () => {
  return document.querySelector('body');
};
