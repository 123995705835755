<script>
import { h } from 'vue';

export default {
  name: 'BaseSpacer',
  functional: true,
  render() {
    return h('div', { class: 'base-spacer' });
  },
};
</script>

<style scoped lang="scss">
.base-spacer {
  flex-grow: 1 !important;
}
</style>
